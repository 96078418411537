import { getRoleList, insertRole, deleteRole } from 'api/account/role';
import RoleMenu from '../../account/components/RoleMenu';
import Pagination from '@/components/pagination';
import { myMixin } from '@/mixins/mixins.js';

const defaultMenu = {
  index: '',
  title: '',
  icon: '',
  weight: '',
  parentId: '',
};
export default {
  mixins: [myMixin],
  components: {
    Pagination,
    RoleMenu,
  },
  data() {
    return {
      toData: [],
      fromData: [],
      delVisible: false,
      role: {
        roleName: null,
        roleDesc: null,
      },
      title: ['可分配菜单', '已分配'],
      addmenus: Object.assign({}, defaultMenu),
      listLoading: false,
      delValue: null,
      roleList: [],
      roleMenuList: [],
      menuList: [],
      allRoles: [],
      rules: {
        title: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
      },
      pageSize: 10,
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    delMenuClick() {
      this.setNull(this.delValue);
      this.delMenuVisible = true;
    },
    getDataList() {
      let param = {
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      getRoleList(param).then((response) => {
        if (response.code == 200) {
          this.roleList = response.result;
          this.total = response.total;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    createClick() {
      if (this.role.roleName == null) {
        this.$message.error('角色不能为空');
        return true;
      }
      insertRole({ ...this.role }).then((response) => {
        if (response.code == 200) {
          this.$message({
            message: 'Add Success',
            type: 'success',
          });
          this.getDataList();
        } else {
          this.$message.error('Add Error ' + response.message);
        }
      });
    },
    deleteClick(row) {
      this.roleId = row.id;
      this.delVisible = true;
    },
    delRow() {
      deleteRole(this.roleId).then((response) => {
        if (response.code == 200) {
          this.$message({
            message: 'Delete Success',
            type: 'success',
          });
          this.getDataList();
        } else {
          this.$message.error('Error' + response.message);
        }
      });
      this.delVisible = false;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getDataList();
    },
  },
};
