<template>
  <div class="roleRightsSetting">
    <!-- <div class="main-Title bgff">
      <h2>Ranking & Search</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form :model="role" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Role Name" label-width="80px" class="mb0">
                <el-input v-model="role.roleName" placeholder="role name here ..."></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Role Description" label-width="120px" class="mb0">
                <el-input v-model="role.roleDesc" placeholder="role desc here ..."></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item class="mb0" label-width="0px">
                <el-button type="primary" @click="createClick">Create Role</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          stripe
          border
          size="mini"
          :data="roleList"
          highlight-current-row
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <role-menu :id="props.row.id"></role-menu>
            </template>
          </el-table-column>
          <el-table-column label="Role ID" prop="id" align="center"></el-table-column>
          <el-table-column label="Role Name" prop="roleName" align="center"></el-table-column>
          <el-table-column
            label="Role Description"
            prop="roleDesc"
            align="center"
          ></el-table-column>
          <el-table-column label="Creator" prop="createBy" align="center"></el-table-column>
          <el-table-column label="Create Time" prop="createTime" align="center"></el-table-column>
          <el-table-column label="Action" align="center" min-width="50">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="deleteClick(scope.row)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
        <!-- 删除提示框 -->
        <el-dialog title="Delete" :visible.sync="delVisible" width="300px" center append-to-body>
          <div class="del-dialog-cnt">Confirm to delete ?</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="delVisible = false">cancel</el-button>
            <el-button type="primary" @click="delRow">confirm</el-button>
          </span>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>
<script>
  import RoleRightsSettingCon from '../controllers/account/RoleRightsSetting';
  export default {
    name: 'RoleRightsSetting',
    ...RoleRightsSettingCon,
  };
</script>
<style lang="scss">
  .el-tree-node__label {
    font-size: 14px;
    margin-left: 4px !important;
  }
</style>
<style lang="scss" scoped></style>
